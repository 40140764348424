import posthog from 'posthog-js';

export default {
  install(Vue) {
    posthog.init(
      'phc_voqbQSdeDneB22EFbxFu13SyTJN9t9Fgnh57SdvZuIY',
      {
        api_host: 'https://us.i.posthog.com',
      }
    );

    Vue.prototype.$posthog = posthog;
  },
};
